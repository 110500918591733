import React from "react"
import ModalVideo from "react-modal-video"
import BackgroundImage from "gatsby-background-image"

import Nav from "./nav"
import AppCta from "./appCta"

import FundingLogos from "../i/logoti.jpg"

export default ({ siteTitle = "", backgroundFluid }) => {
  const [videoOpen, setVideoOpen] = React.useState(false)
  const openVideo = (e) => {
    if (e) {
      e.preventDefault()
    }

    setVideoOpen(true)
  }

  const closeVideo = () => {
    setVideoOpen(false)
  }

  return (
    <>
      <Nav isIndex />
      <BackgroundImage Tag="header" className="pt-175 pb-110 header_6" fluid={backgroundFluid}>
        <div className="container px-xl-0 text-center">
          <div className="row justify-content-center">
            <div className="col-lg-9 pt-100">
              <h1 className="color-white header-shadow">Process your tracks online using real analog gear</h1>
              <div className="headerSubtitle">Mix and master your tracks in real time with full control.</div>
            </div>
            <div className="col-lg-12 mt-80 mb-70 btn_holder">
              <AppCta />
              <button className="landingVideoButton" onClick={openVideo}>
                <i className="fas fa-play" />
                How it works
              </button>
              <ModalVideo channel="youtube" isOpen={videoOpen} videoId="mfspkpiuY4g" onClose={closeVideo} />
            </div>
          </div>
        </div>
        <a href={"https://blog.mixanalog.com/mix-analog-received-funding"} target="_blank" rel="noopener noreferrer">
          <img className={"float-left"} style={{ position: "absolute", maxWidth: "40vw", left: "10px", bottom: "10px" }} width={"300"} src={FundingLogos} alt="eu slo funding" />
        </a>
      </BackgroundImage>
    </>
  )
}
