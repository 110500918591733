/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import AboveTheFold from "./above-the-fold"
import Footer from "./footer"
import "../scss/style.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutIndexSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      bg: file(relativePath: { eq: "bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 8192, base64Width: 620) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <AboveTheFold siteTitle={data.site.siteMetadata.title} backgroundFluid={data.bg.childImageSharp.fluid} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
